<template>
  <b-container>
    <b-row>
      <b-col md="6">
        <title-back back="Propiedad"></title-back>
        <h2>Elige un informe</h2>
        <p class="text-muted mb-4">
          Estas son las opciones que tenemos para que generes tu informe, elige
          la que más se ajuste a tus necesidades.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-3 mb-lg-0" md="6" offset="0">
        <report-prices
          id-modal="basic-example"
          :has-premium="true"
          option="basic"
          price="Gratis"
          :disabled="false"
        />
      </b-col>
      <b-col md="6" offset="0">
        <report-prices
          id-modal="premium-example"
          :has-premium="hasPremium"
          option="premium"
          price="$ 10.000"
          :show-warning-or-button="showWarningOrButton"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TitleBack from "@/components/misc/TitleBack.vue";
import ReportPrices from "@/components/cards/ReportPrices.vue";

import { mapGetters } from "vuex";

import axios from "axios";
import Config from "@/../config.json";

export default {
  name: "ReportChoices",
  components: {
    ReportPrices,
    TitleBack,
  },
  data() {
    return {
      hasPremium: false,
      showWarningOrButton: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedProperty"]),
  },
  async beforeMount() {
    const { data } = await axios(
      `${Config.VUE_APP_URL_GET_ALLOWED_PROPERTIES}?comuna=${this.getSelectedProperty.commune}&rol=${this.getSelectedProperty.role}`
    );

    this.hasPremium = data?.content?.isAllowedCommune ?? false;
    this.showWarningOrButton = true;
  },
};
</script>
