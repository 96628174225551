<template>
  <div>
    <div>
      <PaymentRejected v-if="showPaymentRejected" />
      <TimeoutTransaction v-if="showTimeoutTransaction" />
      <TransactionCanceledByUser v-if="showTransactionCanceledByUser" />
      <CertificateSuccessfullyRequested
        v-if="showSuccessfullyPetitionRequested"
      />
      <CertificateRequestedAfterPayment
        v-if="showCertificateRequestedAfterPayment"
      />

      <b-row
        v-if="showLoader && worksStatus.length"
        class="justify-content-center"
      >
        <b-col lg="8" xl="7">
          <b-button variant="link" class="mb-3" @click="$router.go(-1)">
            <font-awesome-icon icon="fa-solid fa-arrow-left" />
            Volver a propiedad
          </b-button>
          <b-card>
            <loader-report :progress="progress" />
            <b-row
              v-if="showLoader && worksStatus.length > emptyArrayLength"
              class="justify-content-center mt-4"
            >
              <b-col lg="9">
                <status-item
                  v-for="(work, index) in formatedWorks"
                  :key="index"
                  :title="work.name"
                  :status="work.status"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-skeleton-wrapper :loading="showSkeleton">
        <template #loading>
          <b-row class="mb-3">
            <b-col md="8">
              <b-skeleton width="65%" height="20px" class="mb-2"></b-skeleton>
              <b-skeleton width="100%" height="30px"></b-skeleton>
            </b-col>
            <b-col md="4" class="d-flex justify-content-end align-items-end">
              <b-skeleton width="100%" height="30px"></b-skeleton>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-skeleton width="100%" height="200px" class="mb-3"></b-skeleton>
              <b-skeleton width="100%" height="200px" class="mb-3"></b-skeleton>
              <b-skeleton width="100%" height="200px" class="mb-3"></b-skeleton>
            </b-col>
            <b-col md="4">
              <b-skeleton width="100%" height="200px" class="mb-3"></b-skeleton>
            </b-col>
          </b-row>
        </template>

        <div v-if="Object.keys(report).length">
          <b-row class="mb-3">
            <b-col lg="8">
              <title-back back="propiedad" :items="backItems" />
              <h4 class="text-nowrap mb-0 mr-3">
                Informe de propiedad
                <b-badge
                  class="text-capitalize"
                  :variant="reportVariant"
                  pill
                  >{{ formattedReportName }}</b-badge
                >
              </h4>
            </b-col>
          </b-row>

          <b-row class="flex-column-reverse flex-lg-row">
            <b-col lg="8">
              <MiniMap
                v-if="showMap"
                id="mini-map"
                :property-info="propertyToMap"
                :neighbors-to-map="neighborsToMap"
                :main-maker-rotation="25"
                min-height="500px"
              />
              <resume-card
                id="resume"
                :address="address"
                :comuna="comuna"
                :property-name="propertyName"
                :region="region"
                :rol="rol"
              />

              <owners-card id="owners" :proprietary="proprietary" />

              <municipal-info id="municipal" :sii-bd="report.data.siiBbData" />

              <contributions
                id="contributions"
                :simple-real-estate="report.data.simpleRealEstate"
                :debt-contribution="report.data.debtContributionData"
                :pdf="report.data.pdf"
              />

              <div v-if="report.type === 'premium' || report.type === 'full'">
                <history-transfer
                  id="history"
                  :transfer-history="report.data.transferHistory"
                />
                <transfer-price
                  id="transfer"
                  :transf-neighbor="report.data.transfersNeighbors"
                />
                <mortgage
                  v-if="report.type === 'full'"
                  id="mortgage"
                  :mortgages="report.data.mortgages"
                  :prohibitions="report.data.prohibitions"
                />
              </div>
              <top-button text="Volver al tope" />
            </b-col>
            <b-col lg="4" class="position-relative z-5">
              <div class="container-buttons">
                <nav-menu :type-report="report.type" />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-skeleton-wrapper>
    </div>
  </div>
</template>

<script>
import ResumeCard from "./Resume.vue";
import OwnersCard from "./Owners.vue";
import HistoryTransfer from "./HistoryTransfer.vue";
import TransferPrice from "./TransferPrice.vue";
import MunicipalInfo from "./MunicipalInfo.vue";
import Contributions from "./Contributions.vue";
import NavMenu from "./NavMenu.vue";
import Mortgage from "./Mortgage.vue";
import TitleBack from "../misc/TitleBack.vue";
import LoaderReport from "./Loader.vue";
import StatusItem from "./../misc/StatusItem.vue";
import { mapGetters } from "vuex";
import capitalize from "@/utils/capitalize";
import TopButton from "./TopButton.vue";

import CertificateSuccessfullyRequested from "@/views/misc/CertificateSuccessfullyRequested.vue";
import PaymentRejected from "@/views/misc/PaymentRejected.vue";
import TimeoutTransaction from "@/views/misc/TimeoutTransaction.vue";
import TransactionCanceledByUser from "@/views/misc/TransactionCanceledByUser.vue";

import axios from "axios";

import reportService from "@/services/report.service";

import Config from "@/../config.json";
import CertificateRequestedAfterPayment from "@/views/misc/CertificateRequestedAfterPayment.vue";

import {
  formatCertificate,
  getNeighborsToMap,
} from "@/services/formatCertificate.service";

import MiniMap from "../MiniMap.vue";

export default {
  name: "MainReport",
  components: {
    LoaderReport,
    ResumeCard,
    OwnersCard,
    TransferPrice,
    HistoryTransfer,
    MunicipalInfo,
    Contributions,
    NavMenu,
    Mortgage,
    TitleBack,
    CertificateSuccessfullyRequested,
    PaymentRejected,
    TimeoutTransaction,
    TransactionCanceledByUser,
    CertificateRequestedAfterPayment,
    MiniMap,
    StatusItem,
    TopButton,
  },
  beforeRouteLeave: function () {
    this.intervalsIds.forEach((id) => clearInterval(id));
  },

  data() {
    return {
      progress: 0,
      report: {},
      selectedProperty: {},
      pageNumberYear: {},
      loading: false,
      loadingTime: 0,
      inteval: null,
      userData: {},
      reportStatus: null,
      emptyArrayLength: 0,
      showLoader: false,
      showSkeleton: true,
      showSuccessfullyPetitionRequested: false,
      showPaymentRejected: false,
      showTimeoutTransaction: false,
      showTransactionCanceledByUser: false,
      showCertificateRequestedAfterPayment: false,
      intervalsIds: [],
      worksStatus: [],
      lastUpdate: "",
      reportName: "",
      reportVariant: "primary",
      propertyToMap: null,
      propertyToMapColor: "#00FF00",
      isDragable: false,
      neighborsToMap: { type: "FeatureCollection", features: [] },
      showMap: false,
      backItems: [
        {
          text: "Propiedades",
          to: "/corredor/propiedades",
          icon: true,
        },
        {
          text: "",
          to: "",
        },
        {
          text: "Informe",
          active: true,
        },
      ],
      isLastCertificate: false,
    };
  },

  computed: {
    ...mapGetters([
      "getReport",
      "getReportStatus",
      "getSelectedProperty",
      "getUserData",
      "getReportType",
    ]),
    address() {
      return capitalize(this.report.data.simpleRealEstate?.direccion);
    },
    region() {
      return this.selectedProperty.region !== undefined
        ? capitalize(this.selectedProperty.region)
        : "region";
    },
    comuna() {
      return this.selectedProperty.commune !== undefined
        ? capitalize(this.selectedProperty.commune)
        : "comuna";
    },
    rol() {
      return this.selectedProperty.role;
    },
    propertyName() {
      return capitalize(this.selectedProperty.name);
    },
    formattedReportName() {
      if (this.reportName === "basic") return "básico";

      return this.reportName;
    },
    htmlToPdfOptions() {
      return {
        margin: 10,
        filename: "Ficha.pdf",
      };
    },
    proprietary() {
      return [
        {
          nombre:
            typeof this.report?.data?.simpleRealEstate?.propietario === "string"
              ? this.report?.data?.simpleRealEstate?.propietario
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              : "-",
        },
      ];
    },
    formatedWorks() {
      const allowedWorks = [
        "work:debt:contribution",
        "work:debt:contribution:content",
        "work:get:detailed:real:estate",
        "work:get:non-expropiation",
        "work:get:sii",
        "work:get:sii:data:from:file",
        "work:neighbors:from:property",
        "work:property:from:db:using:rol",
        //"work:save:document",
      ];

      const documentsWorkName = ["work:save:document"];

      let filteredWorks = this.worksStatus.filter((work) =>
        allowedWorks.includes(work.name) ? work : undefined
      );

      const onlyDocuments = this.worksStatus.filter((work) =>
        documentsWorkName.includes(work.name) ? work : undefined
      );

      const firstElement = 0;

      const oneDocumentResume = onlyDocuments.reduce((doc1, doc2) => ({
        name: documentsWorkName[firstElement],
        isRunning: doc1.isRunning || doc2.isRunning,
        isDone: doc1.isDone && doc2.isDone,
        isFailed: doc1.isFailed || doc2.isFailed,
      }));

      filteredWorks = [...filteredWorks, oneDocumentResume];

      filteredWorks.sort((a, b) => b.isDone - a.isDone);

      return filteredWorks.map((work) => {
        const formatedNames = {
          "work:debt:contribution": "Certificado de Deuda por Contribuciones ",
          "work:debt:contribution:content": "Deuda por Contribuciones",
          "work:get:detailed:real:estate":
            "Propietario, Avalúo Fiscal y Contribuciones",
          "work:get:non-expropiation": "Certificado de No Expropiación Serviu",
          "work:get:sii": "Certificado de Avalúo Fiscal Simple",
          "work:get:sii:data:from:file":
            "Año de Construcción, Materialidad y Superficies",
          "work:neighbors:from:property":
            "Precio de Transferencia Propiedades Cercanas y Similares",
          "work:property:from:db:using:rol":
            "Precios Históricos de Transferencia de la Propiedad",
          "work:save:document": "Imprimiendo Informe",
        };

        const name = formatedNames[work.name]
          ? formatedNames[work.name]
          : work.name;

        let status = "loading";

        if (work.isRunning) status = "loading";
        if (work.isDone) status = "done";
        if (work.isFailed) status = "error";

        return {
          name,
          status,
        };
      });
    },
  },

  async mounted() {
    this.isLastCertificate = this.$route.query.last;

    this.backItems[1].text = this.getSelectedProperty.name;
    this.backItems[1].to = `/corredor/propiedades/${this.getSelectedProperty.name}`;

    const token = this.$route.query.token_ws;
    const TBK_TOKEN = this.$route.query.TBK_TOKEN;
    const TBK_ID_SESION = this.$route.query.TBK_ID_SESION;
    const TBK_ORDEN_COMPRA = this.$route.query.TBK_ID_SESION;

    if (token !== undefined) {
      const response = await axios({
        url: `${Config.VUE_APP_URL_BASE_TRANSACTION}${token}/commit`,
        method: "POST",
      });

      const AUTHORIZED = "AUTHORIZED";
      const FAILED = "FAILED";
      const errorName = "FAILED";

      if (response.data.transaction.status === AUTHORIZED) {
        this.selectedProperty = await this.getSelectedProperty;
        this.userData = await this.getUserData;

        const { data } = await reportService.requestReport(
          this.userData.id,
          this.selectedProperty.id,
          this.selectedProperty.region,
          this.selectedProperty.commune,
          this.selectedProperty.role,
          await this.getReportType
        );

        if (data.content?.success) {
          this.showLoader = false;
          this.showSkeleton = false;
          this.showCertificateRequestedAfterPayment = true;
        }

        return;
      }

      if (
        response.data.transaction.status === FAILED ||
        response.data.transaction.error.name === errorName
        // || response.data.transaction.success === false
      ) {
        this.showSkeleton = false;
        this.showPaymentRejected = true;
      }
    }

    if (token === undefined && TBK_TOKEN !== undefined) {
      this.showSkeleton = false;
      this.showTransactionCanceledByUser = true;
    }

    if (
      token === undefined &&
      TBK_TOKEN === undefined &&
      TBK_ID_SESION !== undefined &&
      TBK_ORDEN_COMPRA !== undefined
    ) {
      this.showSkeleton = false;
      this.showTimeoutTransaction = true;
    }

    if (
      token === undefined &&
      TBK_TOKEN === undefined &&
      TBK_ID_SESION === undefined &&
      TBK_ORDEN_COMPRA === undefined
    ) {
      this.showSkeleton = false;
      this.showLoader = true;

      await this.evaluatesIfCertificateIsGeneratedOrDisplayed();
    }
  },

  beforeDestroy() {
    this.intervalsIds.forEach((id) => clearInterval(id));
  },
  methods: {
    fetchReportStatus() {},
    getReportNameAndVariant() {
      const reportType = this.report.type;

      if (reportType === "basic") {
        this.reportName = reportType;
        this.reportVariant = "primary";
      }
      if (reportType === "premium") {
        this.reportName = reportType;
        this.reportVariant = "warning";
      }
      if (reportType === "full") {
        this.reportName = reportType;
        this.reportVariant = "success";
      }
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    goToPropertiesList() {
      this.$router.push({
        path: "/corredor/propiedades",
      });
    },
    goToShowReport() {
      this.$router.push({
        path: "/corredor/propiedades/informe",
      });
    },
    showReport() {
      if (
        this.reportStatus?.content?.content?.petition?.is_done ||
        this.isLastCertificate === "true" ||
        this.isLastCertificate === true
      ) {
        this.showReport = true;

        if (this.reportStatus.content.report !== undefined) {
          this.propertyToMap = {
            geometry: this.getSelectedProperty.geojson,
            properties: {
              ...this.getSelectedProperty,
            },
          };

          this.showMap = true;

          if (this.reportStatus.content.report.type === "premium") {
            this.neighborsToMap.features =
              this.reportStatus.content.report.data.mapData.neighborsToMap;
          }

          this.report = JSON.parse(
            JSON.stringify(this.reportStatus.content.report)
          );
          this.getReportNameAndVariant();

          this.showLoader = false;
          this.showSuccessfullyPetitionRequested = false;

          this.intervalsIds.forEach((id) => clearInterval(id));
        }
      }

      if (
        !this.reportStatus?.content?.content?.petition?.is_done &&
        (this.isLastCertificate === "false" || this.isLastCertificate === false)
      ) {
        if (
          this.reportStatus.content?.content?.penultimateCertificate !== null
        ) {
          this.propertyToMap = {
            geometry: this.getSelectedProperty.geojson,
            properties: {
              ...this.getSelectedProperty,
            },
          };
          this.showMap = true;

          if (
            this.reportStatus.content?.content?.penultimateCertificate
              ?.type_id === 2
          ) {
            // 2: "premium"
            this.neighborsToMap.features = getNeighborsToMap(
              this.reportStatus.content?.content?.penultimateCertificate.data
            );
          }

          this.report = formatCertificate(
            this.reportStatus.content?.content?.penultimateCertificate.data,
            this.reportStatus.content?.content?.penultimateCertificate?.type_id
          );

          this.reportName = this.report.type;

          if (this.report.type === "basic") {
            this.reportVariant = "primary";
          }
          if (this.report.type === "premium") {
            this.reportVariant = "warning";
          }
          if (this.report.type === "full") {
            this.reportVariant = "success";
          }

          this.showLoader = false;
          this.showSuccessfullyPetitionRequested = false;

          this.intervalsIds.forEach((id) => clearInterval(id));
        } else {
          if (this.reportStatus.content.report !== undefined) {
            this.propertyToMap = {
              geometry: this.getSelectedProperty.geojson,
              properties: {
                ...this.getSelectedProperty,
              },
            };

            this.showMap = true;

            if (this.reportStatus.content.report.type === "premium") {
              this.neighborsToMap.features =
                this.reportStatus.content.report.data.mapData.neighborsToMap;
            }

            this.report = JSON.parse(
              JSON.stringify(this.reportStatus.content.report)
            );
            this.getReportNameAndVariant();

            this.showLoader = false;
            this.showSuccessfullyPetitionRequested = false;

            this.intervalsIds.forEach((id) => clearInterval(id));
          }
        }
      }
    },
    async evaluatesIfCertificateIsGeneratedOrDisplayed() {
      this.selectedProperty = await this.getSelectedProperty;
      this.userData = await this.getUserData;

      const { data } = await reportService.getReportStatus(
        this.selectedProperty.id
      );
      this.reportStatus = data;

      if (this.$route.query.generated === undefined) {
        if (!this.reportStatus?.content?.content?.petition?.is_done) {
          if (
            this.isLastCertificate === "false" ||
            this.isLastCertificate === false
          ) {
            this.showReport();
            return;
          }

          const { data } = await reportService.getReportStatus(
            this.selectedProperty.id
          );

          this.reportStatus = data;
          this.worksStatus = data.content?.content?.relatedWorks.sort(
            //sort works alphabetically
            (a, b) => {
              if (a.name > b.name) return 1;
              if (b.name > a.name) return -1;
              return 0;
            }
          );

          const worksCompleted = this.worksStatus.filter(
            (work) => work.isDone === true
          );

          this.progress = Math.round(
            (worksCompleted.length / this.worksStatus.length) * 100
          );

          const id = setInterval(async () => {
            const { data } = await reportService.getReportStatus(
              this.selectedProperty.id
            );

            this.reportStatus = data;
            this.worksStatus = data.content?.content?.relatedWorks.sort(
              //sort works alphabetically
              (a, b) => {
                if (a.name > b.name) return 1;
                if (b.name > a.name) return -1;
                return 0;
              }
            );

            const worksCompleted = this.worksStatus.filter(
              (work) => work.isDone === true
            );

            this.progress = Math.round(
              (worksCompleted.length / this.worksStatus.length) * 100
            );

            const date = new Date();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            const textHours = String(hours).length === 1 ? "0" + hours : hours;
            const textMinutes =
              String(minutes).length === 1 ? "0" + minutes : minutes;
            const textSeconds =
              String(seconds).length === 1 ? "0" + seconds : seconds;

            this.lastUpdate = `${textHours}:${textMinutes}:${textSeconds}`;

            if (this.reportStatus?.content?.content?.petition?.is_done) {
              this.showReport();
            }
          }, 5000);

          this.intervalsIds.push(id);
        }

        if (this.reportStatus?.content?.content?.petition?.is_done) {
          this.showReport();
        }
      }

      if (
        this.$route.query.generated === false ||
        this.$route.query.generated === "false"
      ) {
        if (this.reportStatus?.content?.content?.petition === null) {
          const { data } = await reportService.requestReport(
            this.userData.id,
            this.selectedProperty.id,
            this.selectedProperty.region,
            this.selectedProperty.commune,
            this.selectedProperty.role,
            await this.getReportType
          );

          if (data.content?.success) {
            this.showLoader = false;
            this.showSkeleton = false;
            this.showSuccessfullyPetitionRequested = true;
          }

          return;
        }

        if (this.reportStatus?.content?.content?.petition?.is_done) {
          const { data } = await reportService.requestReport(
            this.userData.id,
            this.selectedProperty.id,
            this.selectedProperty.region,
            this.selectedProperty.commune,
            this.selectedProperty.role,
            await this.getReportType
          );

          if (data.content?.success) {
            this.showLoader = false;
            this.showSkeleton = false;
            this.showSuccessfullyPetitionRequested = true;
          }
        }
      }
    },
  },
};
</script>
