/* eslint-disable */
import axios from "axios";
import Config from "@/../config.json";

const reportService = {
  async requestReport(userId, propertyId, region, comuna, rol, type) {
    try {
      const petitionName = `generate:certificate:${type}`;

      const worksParameters = {
        region,
        comuna,
        rol,
      };

      const data = await axios({
        url: `${Config.VUE_APP_URL_REQUEST_CERTIFICATE}/${userId}/${propertyId}`,
        method: "POST",
        data: {
          name: petitionName,
          worksParameters,
        },
      });

      return data;
    } catch (error) {
      throw error;
    }
  },

  async getReportStatus(propertyId) {
    try {
      const data = await axios.get(
        `${Config.VUE_APP_URL_LAST_CERTIFICATE}/${propertyId}/latest`
      );

      return data;
    } catch (error) {
      throw error;
    }
  },
  //This endpoint fetch the report with scrappers and also saves the information on DB
  async getReport(payload) {
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_FREE_CERTIFICATES,
        payload,
        { timeout: 180000 }
      );

      return data;
    } catch (error) {
      throw error;
    }
  },

  //This endpoint get the report data directly from our DB
  async getStoredReport(payload) {
    try {
      const data = await axios.post(Config.VUE_APP_URL_GET_REPORT, payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
export default reportService;
