<template>
  <div>
    <b-list-group v-b-scrollspy="100" class="list-menu-custom mb-4">
      <nav-menu-item title="Ubicación" href="#map" />
      <nav-menu-item title="Resumen propiedad" href="#resume" />
      <nav-menu-item title="Propietarios" href="#owners" />
      <nav-menu-item title="Información municipal" href="#municipal" />
      <nav-menu-item title="Avalúos y contribuciones" href="#contributions" />
      <nav-menu-item
        v-if="showMenuItem"
        title="Historial transferencias"
        href="#history"
        :type-report="typeReport"
        @click="navMenuItemClicked($event)"
      />
      <nav-menu-item
        v-if="showMenuItem"
        title="Precio transferencias"
        href="#transfer"
        :type-report="typeReport"
        @click="navMenuItemClicked($event)"
      />
    </b-list-group>
    <b-modal
      ref="modal-prices"
      title="Elige la opción que necesitas"
      hide-footer
      size="lg"
      class="pl-0"
    >
      <p class="text-muted mb-4">
        Estas son las opciones que tenemos para que generes tu informe, elige la
        que más se ajuste a tus necesidades.
      </p>
      <div class="row justify-content-center">
        <div class="col-sm-6 mb-3 mb-lg-0">
          <report-prices
            :show-warning-or-button="true"
            :has-premium="true"
            id-modal="premium-example"
            option="premium"
            price="$ 10.000"
          />
        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="lg" @click="cancel()">Cancelar</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import NavMenuItem from "./NavMenuItem.vue";
import ReportPrices from "@/components/cards/ReportPrices.vue";

import { mapGetters } from "vuex";

import axios from "axios";
import Config from "@/../config.json";

export default {
  name: "NavMenu",
  components: {
    NavMenuItem,
    ReportPrices,
  },
  props: {
    typeReport: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showMenuItem: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedProperty"]),
  },
  async beforeMount() {
    const { data } = await axios(
      `${Config.VUE_APP_URL_GET_ALLOWED_PROPERTIES}?comuna=${this.getSelectedProperty.commune}&rol=${this.getSelectedProperty.role}`
    );

    this.showMenuItem = data?.content?.isAllowedCommune ?? false;
  },
  methods: {
    navMenuItemClicked(event) {
      if (event.typeReport === "basic") {
        this.$refs["modal-prices"].show();
      }
    },
  },
};
</script>
