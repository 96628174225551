import axios from 'axios';
import Config from "@/../config.json";

const simpleDocuments = {
  async obtainSimpleDocuments(values) {
    const data = await axios.post(
      Config.VUE_APP_URL_OBTAIN_SIMPLE_DOCUMENTS,
      values
      ) 
      .catch((err) => { return (err.response.data.message) })
      ;
    return data.data.simpleDocuments.content;
  },

};
export default simpleDocuments;