import axios from 'axios';
import Config from "@/../config.json";

const document = {
  async saveDocument(values) {

    const data = await axios.post( 
      Config.VUE_APP_URL_SAVE_DOCUMENT,
      values
    ).catch((err)=>{return(err.response.data.message)})
    ;
   
    return data;
  
  },
  async viewDocument(values) {

    const data = await axios.post( 
      Config.VUE_APP_URL_VIEW_DOCUMENT,
      values
    ).catch((err)=>{return(err.response.data.message)})
    ;
   
    return data;
  
  },
  async deleteDocument(values) {

    const data = await axios.delete( 
      Config.VUE_APP_URL_DELETE_DOCUMENT,
      {data: values}
    ).catch((err)=>{return(err.response.data.message)})
    ;
   
    return data;
  
  },
};
export default document;