import axios from "axios";
import Config from "@/../config.json";

const requestService = {
  async getRequests(payload) {
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_REQUESTS_LIST, {
        user_id: payload,
      });
      return data.requestList.content;
    } catch (error) {
      throw error;
    }
  },

  async registerRequest(payload) {
    try {
      const data = await axios.post(Config.VUE_APP_URL_REGISTER_REQUEST, payload);
      return data;
    } catch (error) {
      throw error;
    }
  }, 

  async adminRequestsList(payload) {
    try {
      const { data } = await axios.get(Config.VUE_APP_URL_ADMIN_REQUEST_LIST);
      return data.adminRequestList.content;
    } catch (error) {
      throw error;
    }
  },

  async adminUploadFileRequest(payload) {
    try {
      const data = await axios.post(Config.VUE_APP_URL_ADMIN_UPLOAD_FILE, payload);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async adminFinishRequest(payload) {
    try {
      const data = await axios.post(Config.VUE_APP_URL_ADMIN_FINISH_REQUEST, payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
export default requestService;
