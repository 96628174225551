var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-nav',{attrs:{"fill":"","vertical":_vm.vertical}},_vm._l((_vm.quantity),function(item){return _c('b-nav-item',{key:item,class:_vm.vertical
          ? 'vertical-height'
          : item == 1
          ? 'text-left horizontal-height'
          : item == _vm.quantity
          ? 'text-right horizontal-height'
          : 'horizontal-height',attrs:{"link-classes":"p-0","disabled":""}},[_c('b-row',[_c('b-col',{class:_vm.vertical ? 'col-2 px-0 text-right' : ''},[_c('b-avatar',{class:[
              _vm.vertical
                ? _vm.getStep >= item
                  ? ("bg-" + (_vm.content[item - 1].title == 'Anulado'
                        ? _vm.content[item - 1].status + ' text-white'
                        : 'stepper text-dark') + " ")
                  : ("bg-" + (_vm.content[item - 1].status != 'primary'
                        ? _vm.content[item - 1].status
                        : 'primary'))
                : _vm.getStep >= item
                ? 'bg-primary bottom-avatar'
                : 'bg-stepper text-dark bottom-avatar' ]},[_c('font-awesome-icon',{class:[
                _vm.vertical
                  ? _vm.getStep >= item && _vm.content[item - 1].title != 'Anulado'
                    ? 'd-none'
                    : 'd-block'
                  : _vm.getStep <= item
                  ? 'd-none'
                  : 'd-block' ],attrs:{"size":"lg","icon":[
                'fas',
                _vm.vertical
                  ? _vm.content[item - 1].status == 'primary'
                    ? 'check'
                    : _vm.content[item - 1].status == 'danger'
                    ? 'xmark-large'
                    : 'exclamation'
                  : 'check' ]}}),_c('h5',{staticClass:"mb-0",class:[
                _vm.vertical
                  ? _vm.getStep >= item && _vm.content[item - 1].title != 'Anulado'
                    ? 'd-block'
                    : 'd-none'
                  : _vm.getStep <= item
                  ? 'd-block'
                  : 'd-none' ]},[_vm._v(" "+_vm._s(_vm.vertical ? _vm.quantity + (1 - item) : item)+" ")])],1),_c('div',{class:[
              _vm.vertical
                ? item != _vm.quantity
                  ? 'vertical-line'
                  : ''
                : item == 1
                ? 'left-line'
                : item == _vm.quantity
                ? 'right-line'
                : 'separator-line' ]})],1),_c('b-col',{staticClass:"pr-0",class:[_vm.vertical ? 'd-block' : 'd-none']},[_vm._t("content",function(){return [_c('h5',{staticClass:"text-dark"},[_vm._v(_vm._s(_vm.content[item - 1].title))]),_c('p',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.content[item - 1].date))])]})],2)],1)],1)}),1),_vm._t("subtitle",null,{"step":_vm.getStep}),_c('b-card',{class:_vm.vertical ? 'd-none' : 'd-block'},[_vm._t("panel",null,{"step":_vm.getStep})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }