import axios from 'axios';
import Config from "@/../config.json";

const login = {
  async getLogin(values) {
    const data = await axios.post(
      Config.VUE_APP_URL_LOGIN,
      values
      ) 
      .catch((err) => { return (err.response.data.message) })
      ;
    return data;

  },

};
export default login;
