import axios from 'axios';
import Config from "@/../config.json";

const pageNumberYearService = {
  async getPageNumberYear(values) {
    const data = await axios.post(
      Config.VUE_APP_URL_PAGE_NUMBER_YEAR,
      values
      ) 
      .catch((err) => { return (err.response.data.message) })
      ;
    return data;
  }, 
  async savePageNumberYear(values) {
    try {
      const response = await axios.put(Config.VUE_APP_URL_SAVE_PAGE_NUMBER_YEAR, values); 
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export default pageNumberYearService;