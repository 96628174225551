<template>
  <b-card class="mb-3">
    <b-row align-h="between">
      <b-col xl="5" md="6">
        <div class="media">
          <div class="icon-circle mr-3" :class="`bg-${button.variant}`">
            <font-awesome-icon :icon="['fa-solid', icon]" class="text-white" />
          </div>
          <div class="media-body">
            <h4>{{ title }}</h4>
            <p class="text-muted mb-2">{{ subtitle }}</p>
          </div>
        </div>
      </b-col>
      <b-col xl="6" md="6">
        <b-row align-h="end">
          <b-col v-if="hasInform" xl="6">
            <b-button
              class="mb-2"
              variant="outline-secondary"
              size="lg"
              block
              :disabled="!isLastCertificateDone"
              @click="
                $router.push({
                  name: 'ReportChoices',
                })
              "
              >Generar nuevo informe</b-button
            >
          </b-col>
          <b-col v-if="showButton" xl="6">
            <b-button
              class="mb-2"
              :variant="button.variant"
              size="lg"
              block
              :disabled="!isLastCertificateDone && !showPenultimateCertificate"
              @click="conduct()"
              >{{ button.caption }}
            </b-button>
          </b-col>
        </b-row>
        <p
          v-if="showPenultimateCertificate"
          class="text-right text-muted small mt-2"
        >
          *Último Informe generado el {{ report.date }}
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "ShortCuts",
  props: {
    title: {
      type: String,
      default: "Título",
      required: true,
    },
    subtitle: {
      type: String,
      default: "Contenido",
      required: true,
    },
    icon: {
      type: String,
      default: "file-lines",
    },
    button: {
      type: Object,
      default: {},
      required: true,
    },
    report: {
      type: Object,
      default: () => ({}),
    },
    hasInform: {
      type: Boolean,
      default: false,
    },
    isLastCertificateDone: {
      type: Boolean,
      default: true,
    },
    showPenultimateCertificate: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showButton() {
      if (this.button.caption !== "Ver informe") return true;
      else if (this.showPenultimateCertificate) return true;

      return false;
    },
  },
  methods: {
    conduct() {
      if (this.title === "Informe de la propiedad") {
        if (this.hasInform) {
          this.$router.push({
            name: "Report",
            query: {
              // generated: true,
              // type: this.report.inform,
              last: false,
            },
          });
        } else {
          this.$router.push({
            name: "ReportChoices",
          });
        }
      }
      else if (this.title === "Valorización de la propiedad") {
        this.$router.push({
          name: "Appraiser",
        }); 
      }
      else {
        if (this.title === "Solicitud de documentos") {
          this.$store.commit("setStep", 2);
          this.$store.commit(
            "setRequestSelectedProperty",
            this.$store.getters.getSelectedProperty
          );
          this.$router.push({
            name: this.button.pathName,
          });
        } else {
          this.$router.push({
            name: this.button.pathName,
            params: { unit: this.button.unit },
          });
        }
      }
    },
  },
};
</script>
