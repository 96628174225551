import axios from "axios";
import Config from "@/../config.json";

const propertyService = {

  async getIndicators(payload) {
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_INDICATORS_LIST, payload);
      return data;
    } catch (error) {
      return error;
      //throw error;
    }
  },

  async getIndicatorsByToken(payload) {
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_GET_INDICATORS_BY_TOKEN, payload);
      return data;
    } catch (error) {
      return error;
      //throw error;
    }
  },

  async getProperties(payload) {
    try {
      const { data } = await axios.post(Config.VUE_APP_URL_PROPERTIES_LIST, {
        user_id: payload,
      });
      return data.properties;
    } catch (error) {
      throw error;
    }
  },

  async saveProperty(payload) {
    try {
      const data = await axios.post(Config.VUE_APP_URL_SAVE_PROPERTY, payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
  async deleteProperty(payload) {
    try {
      const data = await axios.delete(Config.VUE_APP_URL_DELETE_PROPERTY, {
        data: payload,
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  async updateProperty(payload) {
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_UPDATE_PROPERTY,
        payload
      );
    } catch (error) {
      throw error;
    }
  },
  async updateImage(payload) {
    try {
      const response = await axios.post(Config.VUE_APP_URL_UPDATE_PROPERTY_IMAGES, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },  
  async getImages(propertyId) {
    try {
      const body = {
        token: '$a89fd6a$_l',
        propertyId: propertyId
      };
      console.log(body);
      const response = await axios.post(Config.VUE_APP_URL_GET_PROPERTY_IMAGES, body);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async deleteImages(propertyId) {
    try {
      await axios.delete(`${Config.VUE_APP_URL_DELETE_PROPERTY_IMAGES}/${propertyId}`);
    } catch (error) {
      throw error;
    }
  },
  async rolSearch(payload) {
    const url = `${Config.VUE_APP_URL_GET_PROPERTY_BY_ADDRESS}?region=${payload.region}&comuna=${payload.comuna}&street=${payload.street}&number=${payload.number}&department_number=${payload.department_number}`;
    try {
      const { data } = await axios.get(url);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async infoSearch(payload) {
    const url = `${Config.VUE_APP_URL_GET_PROPERTY_INFO_BY_ROLE}`;
    try {
      const { data } = await axios.post(url, payload);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async rolSearchByCoords(payload) {
    const url = `${Config.VUE_APP_URL_GET_PROPERTY_BY_COORDS}`;
    try {
      const { data } = await axios.post(url, payload);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async rolSearchByAddr(payload) {
    const url = `${Config.VUE_APP_URL_GET_PROPERTY_BY_DIRE}`;
    try {
      const { data } = await axios.post(url, payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
export default propertyService;
