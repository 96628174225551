<template>
  <div>
    <b-row align-v="end" class="mb-3">
      <b-col cols="12">
        <title-back back="Propiedad" :items="backItems">
        </title-back>
      </b-col>
      <b-col>
        <h3 class="mt-2">Documentos</h3>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          v-b-modal.modal-share
          variant="outline-secondary"
          size="lg"
          class="mr-3"
        >
          <font-awesome-icon
            icon="fa-solid fa-share-nodes"
            class="mr-0 mr-md-2"
          />
          <span class="d-none d-md-inline-block">Compartir</span>
        </b-button>
        <b-button v-b-modal.modal-upload variant="secondary" size="lg">
          <font-awesome-icon
            icon="fa-solid fa-plus-large"
            class="text-white mr-0 mr-md-2"
          />
          <span class="d-none d-md-inline-block">Subir archivo</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-tabs v-model="tabIndex" class="nav-tab-custom" content-class="mt-3">
          <b-tab
            v-for="(folder, index) in folders"
            :key="index"
            :title="folder.title"
            lazy
          >
            <b-list-group class="mb-4">
              <document-item
                v-for="(document, filteredIndex) in filteredDocuments"
                :id="String(document.id)"
                :key="filteredIndex"
                :name="document.name"
                :source="document.source"
                :date="document.createAt"
                :type="document.type"
              />
              <b-card v-if="filteredDocuments.length === 0">
                <b-row class="justify-content-center">
                  <b-col cols="8" sm="4" lg="2">
                    <img
                      :src="require(`../../assets/img-empty.svg`)"
                      alt="Empty state"
                      class="img-fluid mb-4"
                    />
                  </b-col>
                </b-row>
                <b-row class="justify-content-center">
                  <b-col lg="4">
                    <p class="text-center text-muted">
                      No hay documentos para mostrar
                    </p>
                  </b-col>
                </b-row>
              </b-card>
            </b-list-group>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <modal-upload />
    <modal-share />
  </div>
</template>

<script>
import TitleBack from "@/components/misc/TitleBack.vue";
import DocumentItem from "@/components/cards/DocumentItem.vue";
import ModalUpload from "@/components/modals/ModalUpload.vue";
import ModalShare from "@/components/modals/ModalShare.vue";
import { mapGetters } from "vuex";

export default {
  name: "FolderDocuments",
  components: { TitleBack, DocumentItem, ModalUpload, ModalShare },
  data() {
    return {
      documents: [],
      folders: [
        { title: "Propietarios" },
        { title: "Legales" },
        { title: "Comerciales" },
        { title: "Postventa" },
        { title: "Informes Generados" },
        { title: "Otros" },
      ],
      fields: [
        { key: "name", label: "Nombre" },
        { key: "date", label: "Subido el" },
        {
          key: "options",
          label: "",
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
      items: [
        {
          name: "Certificado Municipal",
          date: "15-08-2022",
        },
        {
          name: "Certificado Municipal",
          date: "15-08-2022",
        },
        {
          name: "Certificado Municipal",
          date: "15-08-2022",
        },
        {
          name: "Certificado Municipal",
          date: "15-08-2022",
        },
      ],
      tabIndex: 0,
      backItems: [
        {
          text: "Propiedades",
          to: "/corredor/propiedades",
          icon: true,
        },
        {
          text: "",
          to: "",
        },
        {
          text: "Documentos",
          active: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getSubFolders",
      "getShared",
      "getDocuments",
      "getSelectedProperty",
    ]),
    filteredDocuments() {
      return Object.values(this.getDocuments).filter(
        (doc) =>
          doc.folders[0] ===
          this.folders[this.tabIndex].title.toLowerCase().split(" ")[0]
      );
    },
  },
  async mounted() {
    this.backItems[1].text = this.getSelectedProperty.name;
    this.backItems[1].to = `/corredor/propiedades/${this.getSelectedProperty.name}`;

    await this.$store.dispatch("fetchDocuments", {
      propertyId: this.$store.getters.getSelectedProperty.id,
    });
  },
};
</script>
