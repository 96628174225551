<template>
  <div class="d-flex">
    <div class="vh-100 bg-white auth-content d-flex align-items-center">
      <b-container class="mt-5">
        <b-row class="justify-content-between">
          <b-col lg="8" class="mx-auto">
            <div>
              <img src="../../assets/logo-iprop-green.svg" alt="" class="mb-5"/> <small class="mr-1"><b>Powered by Inciti</b></small>
              <h2 class="text-dark mb-3">Empieza ahora ✌🏻</h2>
              <p class="mb-5">Introduce tus datos para ingresar a tu cuenta</p>
              <form-login/>
              <p class="text-center text-dark align-items-center" style="font-size:12px;">2023 iProp® by Inciti®. Todos los derechos reservados.</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="vh-100 bg-login d-none d-lg-flex align-items-center">
      <b-container class="mt-5">
        <b-row>
          <b-col lg="10" class="mx-auto">
            <h4 class="text-white mb-5">Tu propiedad en tus manos</h4>
            <h1 class="text-white">Desbloqueamos lo complejo del <span class="text-terciary">sector inmobiliario</span></h1>
            <img src="../../assets/img-login.png" alt="" class="d-block img-fluid">
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import FormLogin from "@/components/forms/FormLogin.vue";
export default {
  name: "UserLogin",
  components: { FormLogin },
};
</script>
