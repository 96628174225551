<template>
  <b-container class="p-4" fluid>
    <b-row class="justify-content-center">
      <b-col sm="5" class="text-center">
        <img
          :src="require(`../../assets/img-success-report.svg`)"
          class="img-fluid d-block mx-auto mb-4"
          alt=""
        />
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col class="text-center" sm="8" lg="6" xl="5">
        <h4>Listo!</h4>
        <p class="text-muted mb-4">
          Estamos generando tu informe y te notificaremos por correo en 5-15 minutos cuando esté listo. Puedes hacer seguimiento del proceso en nuestra plataforma.
        </p>
        <b-button variant="primary" size="lg" @click="goToPropertyHome"
          >Entendido</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CertificateSuccessfullyRequested",
  computed: {
    ...mapGetters(["getSelectedProperty"]),
  },
  methods: {
    goToPropertyHome() {
      this.$router.push({
        path: `/corredor/propiedades/${this.getSelectedProperty.name}`,
      });
    },
  },
};
</script>

<style></style>
