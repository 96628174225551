import store from "@/store";
export default function guardMyroute(to, from, next) {
  //Handle routes that requiredAuth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getIsLoggedIn) {
      next({ name: "Login" });
    } else if (to.path.search("corredor") > 0 && store.getters.role === 1) {
      next();
    } else if (to.path.search("corredor") < 0 && store.getters.role === 1) {
      next({ name: "Corredor" });
    } else if (to.path.search("admin") > 0 && store.getters.role === 4) {
      next();
    } else if (to.path.search("admin") < 0 && store.getters.role === 4) {
      next({ name: "adminRequests" });
    } else {
      next();
    }
  }
  // Handle routes that are hidden after login
  if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (store.getters.getIsLoggedIn) {
      //This will be remove after Rol Definitions are done.
      if (store.getters.role === 1) {
        next({ name: "Corredor" });
      }
      if (store.getters.role === 4) {
        next({ name: "adminRequests" });
      }
    } else {
      next();
    }
  }
  // //Probably role handler will come here.
  next();
}
