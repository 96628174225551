<template>
  <b-form novalidate @submit.prevent="onSubmit">
    <b-card class="mb-3">
      <b-row class="mb-4">
        <b-col sm="12" md="12">
          <b-form-group 
            id="input-group-1" 
            label="Contraseña actual"
            label-for="input-1" 
            class="font-weight-bold mb-0"
          >
            <b-form-input
              id="input-1"
              v-model="form.currentPassword"
              type="password"
              required
              size="lg"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="12" md="12">
          <b-form-group 
            id="input-group-2"
            label="Nueva contraseña" 
            label-for="input-2" 
            class="font-weight-bold mb-0"
          >
            <b-form-input
              id="input-2"
              v-model="form.newPassword"
              :state="newPasswordState"
              type="password"
              required
              size="lg"
              @keydown.space.prevent @copy.prevent @paste.prevent
              @blur.native="validatePassword(form.newPassword,hasBlurred.newPassword)"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ hasBlurred.newPassword.content }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-form-group 
            id="input-group-3" 
            label="Confirmar contraseña" 
            label-for="input-3" 
            class="font-weight-bold mb-0"
          >
            <b-form-input
              id="input-3"
              v-model="form.confirmPassword"
              :state="confirmPasswordState"
              type="password"
              required
              size="lg"
              @keydown.space.prevent @copy.prevent @paste.prevent
              @blur.native="validatePassword(form.confirmPassword,hasBlurred.confirmPassword)"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ hasBlurred.confirmPassword.content }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button variant="primary" type="submit" size="lg">Guardar cambios</b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
export default {
  name: "FormPassword",
  data() {
    return {
      form: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      hasBlurred: {
        newPassword: {
          state: null,
          content: ''
        },
        confirmPassword: {
          state: null,
          content: ''
        },
      },
    };
  },
  computed: {
    newPasswordState() {
      return this.hasBlurred.newPassword.state;
    },
    confirmPasswordState() {
      return this.hasBlurred.confirmPassword.state;
    },
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.validatePassword(this.form.newPassword, this.hasBlurred.newPassword);
      this.validatePassword(this.form.confirmPassword, this.hasBlurred.confirmPassword);
      this.validatePasswordEquality();

      if (this.hasBlurred.newPassword.state === null && this.hasBlurred.confirmPassword.state === null) {
        await this.$store.dispatch('changePassword', this.form);
      }
      if (this.$store.getters.getMsg !== null) {
          if(this.$store.getters.getMsg.variant === "success"){
            this.$bvToast.toast(this.$store.getters.getMsg.msg+'.', {
              title: "¡Actualización exitosa!",
              solid: true,
              variant: this.$store.getters.getMsg.variant,
            });
            this.form.currentPassword = "";
            this.form.newPassword = "";
            this.form.confirmPassword = "";
          } else {
            this.$bvToast.toast(this.$store.getters.getMsg.msg, {
              title: "¡Hubo un problema!",
              solid: true,
              variant: this.$store.getters.getMsg.variant,
            });
          }
        }
    },
    validatePassword(value, obj) {
      if (value === '') {
        obj.state = false;
        obj.content = 'Debes ingresar tu contraseña.';
      } else {
        obj.state = value.length > 7 ? null : false;
        obj.content = 'Contraseña debe tener al menos 8 caracteres.';
      }
    },
    validatePasswordEquality() {
      if (this.form.newPassword !== this.form.confirmPassword) {
        this.hasBlurred.confirmPassword.state = false;
        this.hasBlurred.confirmPassword.content = 'La contraseña no coincide.';
      }
    },
  }
};
</script>