<template>
  <b-container>
    <b-row>
      <div id="map" :style="{ minHeight: minHeight }"></div>
    </b-row>
  </b-container>
</template>

<script>
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import reportService from "@/services/report.service";

import { mapGetters } from "vuex";
import { eventBus } from '@/main';

export default {
  name: "MiniMap2",
  props: {
    showMarkers: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: String,
      default: "500px",
    },
    zoom: {
      type: Number,
      default: 13,
    },
    mainMakerRotation: {
      type: Number,
      default: 0,
    },
    neighborsToMap: {
      type: Object,
      default() {
        return { type: "FeatureCollection", features: [] };
      },
    },
    neighborsColor: {
      type: String,
      default: "#FFB100",
    },
    showTooltips: {
      type: Boolean,
      default: true,
    },
    isMainMarkerDragable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resumeProperty: null,
      selectedProperty: null,
      reportStatus: null,
      markerColorLimit: 5500,
      colorVecino: 'green',
      map: null,
      currentMarkers: [],
    };
  },

  computed: {
    ...mapGetters([
      "getResumeProperty",
      "getReportStatus",
      "getSelectedProperty",
      "getBoundingBox",
      "getInfoProperty"
    ]),
  },

  async mounted() {
    this.resumeProperty = this.getResumeProperty;
    this.selectedProperty = this.getSelectedProperty;

    /*
    const { data } = await reportService.getReportStatus(
      this.selectedProperty.id
    );
    this.reportStatus = data;
    */

    const mapboxToken =
      "pk.eyJ1Ijoiam9obnBpdHQiLCJhIjoiY2xoNmNkMzJrMDBuaTNybnU3MjdsZXR4aSJ9.jbLX3CSFov-doev26Lc2Aw";

    mapboxgl.accessToken = mapboxToken;
    console.log("lon2");
    console.log(this.getInfoProperty.data.lon);
    console.log("lat2");
    console.log(this.getInfoProperty.data.lat);
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/johnpitt/clh6kpvmq01il01qj9nmf5vze",
      center: [
        this.getInfoProperty.data.lon,
        this.getInfoProperty.data.lat,
      ],
      zoom: this.zoom,
    });

    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.FullscreenControl());

    if (this.showMarkers) {
      this.map.on("load", () => {
        console.log("lon1");
        console.log(this.getInfoProperty.data.lon);
        console.log("lat1");
        console.log(this.getInfoProperty.data.lat);
        const marker = new mapboxgl.Marker({
          draggable: false,
          color: "#bf9000",
          rotation: this.mainMakerRotation,
        })
          .setLngLat([
            this.getInfoProperty.data.lon,
            this.getInfoProperty.data.lat,
          ])
          .addTo(this.map);
        
        
        //Se agrega el barrio al mapa. Debiese llevar un flag para saber si se muestra o no
        this.map.addSource('barrio1', {
        'type': 'geojson',
        'data': {
        'type': 'Feature',
        'geometry': {
        'type': 'Polygon',
        // These coordinates outline Maine.
        'coordinates': this.getBoundingBox
        }
        }
        });
        
        // Add a new layer to visualize the polygon.
        console.log(this.getBoundingBox);
        this.map.addLayer({
        'id': 'barrio1',
        'type': 'fill',
        'source': 'barrio1', // reference the data source
        'layout': {},
        'paint': {
        'fill-color': '#21675e', // blue color fill
        'fill-opacity': 0.3
        }
        });
        // Add a black outline around the polygon.
        this.map.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'barrio1',
        'layout': {},
        'paint': {
        'line-color': '#000',
        'line-width': 1
        }
        });
        
        if (this.isMainMarkerDragable) {
          const onDragEnd = () => {
            const lngLat = marker.getLngLat();

            if (this.resumeProperty?.geojson?.coordinates) {
              this.resumeProperty.geojson.coordinates = [
                lngLat.lng,
                lngLat.lat,
              ];

              this.$store.commit("setResumeProperty", this.resumeProperty);
            }
          };

          marker.on("dragend", onDragEnd);
        }

        console.log(this.neighborsToMap);
        for (const neighbor of this.neighborsToMap.features[0].features) {
          console.log(neighbor);
          if (neighbor?.properties?.uf > this.markerColorLimit) {
            this.colorVecino = 'red';
          }
          else {
            this.colorVecino = 'green';
          }
          const marker = new mapboxgl.Marker({
            draggable: false,
            color: this.colorVecino,
          })
            .setLngLat([
              neighbor.geometry.coordinates[0],
              neighbor.geometry.coordinates[1],
            ])
            .addTo(this.map);

          this.currentMarkers.push(marker);

          let formattedUf = null;
          const thousands = 1000;

          typeof neighbor?.properties?.uf === "number" &&
          neighbor?.properties?.uf >= thousands
            ? (formattedUf = (neighbor?.properties?.uf / thousands).toFixed(3))
            : (formattedUf = neighbor?.properties?.uf);

          const address = neighbor?.properties?.direccion ?? "";
          const departament = neighbor?.properties?.depto ?? "";

          const formattedAddress =
            departament === "" ? address : `${address}, ${departament}`;

          if (this.showTooltips) {
            const popup = new mapboxgl.Popup({
              closeButton: true,
              closeOnClick: true,
            }).setHTML(`
              <div class="container">
                <div class="row mt-1">
                  <div class="col">
                    <h6>Propiedad cercana y/o similar:</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Comuna:</span>
                    <span class="text-muted">
                      ${
                        neighbor?.properties?.comuna ?? "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Rol:</span>
                    <span class="text-muted">
                      ${neighbor?.properties?.rol ?? "Sin información"}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Dirección:</span>
                    <span class="text-muted">
                      ${formattedAddress ?? "Sin información"}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Propietario:</span>
                    <span class="text-muted">
                      ${
                        neighbor?.properties?.comprador ?? "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Año construcción:</span>
                    <span class="text-muted">
                    ${
                      neighbor?.properties?.anoConstruccion ?? "Sin información"
                    }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Superficie Útil:</span>
                    <span class="text-muted">
                    ${
                      neighbor?.properties?.supCons
                        ? neighbor?.properties?.supCons + " m²"
                        : "Sin información"
                    }
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Superficie Terreno:</span>
                    <span class="text-muted">
                    ${
                      neighbor?.properties?.sutTTe
                        ? neighbor?.properties?.sutTTe + " m²"
                        : "Sin información"
                    }
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Año Última Transferencia:</span>
                    <span class="text-muted">
                      ${
                        typeof neighbor?.properties?.fecha === "string"
                          ? neighbor?.properties?.fecha
                              ?.split("T")[0]
                              ?.split("-")
                              ?.reverse()
                              ?.join("-")
                          : "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Precio Transf:</span>
                    <span class="text-muted">
                      ${formattedUf ? formattedUf + " UF" : "Sin información"}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Precio UF/m2:</span>
                    <span class="text-muted">
                      ${
                        neighbor?.properties?.ufM2U
                          ? neighbor?.properties?.ufM2U + " UF"
                          : "Sin información"
                      }
                      </span>
                  </div>
                </div>
              </div>
            `);
            const markerDiv = marker.getElement();

            markerDiv.addEventListener("click", () => {
              marker.setPopup(popup);
              marker.togglePopup();
            });
            // markerDiv.addEventListener("mouseenter", () => {
            //   // marker.setPopup(popup);
            //   // marker.togglePopup();
            // });
            // markerDiv.addEventListener("mouseleave", () => {
            //   // marker.togglePopup();
            // });
          }
        }
      });
    }
  },

  created() {
    eventBus.$on('changeMarkers', data => {
      this.markerColorLimit = data;
      //update map and markers
      if (this.currentMarkers!==null) {
            for (var i = this.currentMarkers.length - 1; i >= 0; i--) {
            this.currentMarkers[i].remove();
            }
        }
      this.currentMarkers = [];
      for (const neighbor of this.neighborsToMap.features[0].features) {
          console.log(neighbor);
          if (neighbor?.properties?.uf > this.markerColorLimit) {
            this.colorVecino = 'red';
          }
          else {
            this.colorVecino = 'green';
          }
          
          // crea nuevos marcares con el color correspondiente
          const marker = new mapboxgl.Marker({
            draggable: false,
            color: this.colorVecino,
          })
            .setLngLat([
              neighbor.geometry.coordinates[0],
              neighbor.geometry.coordinates[1],
            ])
            .addTo(this.map);
        
          this.currentMarkers.push(marker);
        
      }



    });
  }
};
</script>

<style>
#map {
  top: 0%;
  bottom: 0%;
  left: 0%;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;

  border-radius: 0.5rem;
  border: 1px solid #dee2e6;
}

.mapboxgl-popup {
  min-width: 320px;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.mapboxgl-marker {
  cursor: pointer;
}
</style>
