import axios from "axios";
import Config from "@/../config.json";

const simpleRealEstateService = {
  async getSimpleRealEstate(values) {
    try {
      const data = await axios.post(
        Config.VUE_APP_URL_BIEN_RAIZ_SIMPLE,
        values
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  async getAddressByRol(values) {
    try {
      const data = await axios(
        `http://13.56.238.177:3000/bff/v1/iproperty-bff/address-by-rol?region=${values.region}&comuna=${values.commune}&rol=${values.role}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
export default simpleRealEstateService;
