<template>
  <b-list-group-item id="id">
    <b-row>
      <b-col md="6" lg="8">
        <b-row>
          <b-col
            cols="12"
            lg="6"
            class="d-flex align-items-center mb-2 mb-lg-0"
          >
            <h6 class="mb-0">{{ name }}</h6>
          </b-col>
          <b-col
            cols="12"
            lg="6"
            class="d-flex align-items-center mb-2 mb-lg-0"
          >
            <span class="text-muted">Subido el {{ date }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          v-if="type === 'application/pdf'"
          @click="viewDocument(id, source)"
          variant="outline-secondary"
          size="sm"
          class="mr-2"
          >Ver documento</b-button
        >
        <b-button
          variant="outline-secondary"
          size="sm"
          class="mr-2"
          :download="name"
          :href="document.content"
          title="Descargar documento pdf"
        >
          Descargar
        </b-button>
      </b-col>
    </b-row>
    <b-modal :id="`viewDocument${id}${source}`" :title="name" size="lg" lazy>
      <vue-pdf-embed :source="document.content" />
      <template #modal-footer>
        <b-button
          variant="primary"
          size="sm"
          class="mr-2"
          :download="name"
          :href="document.content"
          title="Descargar documento pdf"
        >
          Descargar
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel(`viewDocument${id}${source}`)"
        >
          Salir
        </b-button>
      </template>
    </b-modal>
  </b-list-group-item>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "DocumentItem",
  components: { VuePdfEmbed },
  props: {
    id: {
      type: String,
      default: "",
    },
    source: {
      type: String,
      default: "",
    },
    file: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "Certificado",
    },
    type: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "01-01-2021",
    },
  },
  computed: {
    document() {
      return this.$store.getters.getDocument !== undefined
        ? this.$store.getters.getDocument
        : null;
    },
  },
  methods: {
    viewDocument() {
      this.$store
        .dispatch("viewDocument", { documentId: this.id, source: this.source })
        .then(() => {
          this.$bvModal.show(`viewDocument${this.id}${this.source}`);
        });
    },
    cancel(id) {
      this.$bvModal.hide(id);
    },
  },
};
</script>
