<template>
  <b-container>
    <b-row>
      <b-col>
        <div id="map-admin">
          <div v-if="showSpinner" class="map-admin-spinner">
            <b-spinner variant="primary" label="Loading..."></b-spinner>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

import LegendControl from "mapboxgl-legend";
import "mapboxgl-legend/dist/style.css";

import axios from "axios";

import Config from "@/../config.json";

export default {
  name: "MiniMapAdmin",
  data() {
    return {
      showSpinner: true,
    };
  },
  async mounted() {
    const { data } = await axios(Config.VUE_APP_URL_GET_ALL_TRANSFERS);
    const transfers = data?.content?.content ? data?.content?.content : [];

    this.showSpinner = false;

    const mapboxToken =
      "pk.eyJ1Ijoiam9obnBpdHQiLCJhIjoiY2xoNmNkMzJrMDBuaTNybnU3MjdsZXR4aSJ9.jbLX3CSFov-doev26Lc2Aw";

    mapboxgl.accessToken = mapboxToken;

    const map = new mapboxgl.Map({
      container: "map-admin",
      style: "mapbox://styles/johnpitt/clh6kpvmq01il01qj9nmf5vze",
      center: [-70.653676, -33.447487],
      zoom: 10.5,
    });

    let geocoder = new MapboxGeocoder({
      accessToken: mapboxToken,
      mapboxgl: mapboxgl,
      collapsed: true,
      placeholder: "Buscar",
      autocomplete: true,
      marker: true,
    });

    const legend = new LegendControl({
      layers: {
        "transfers-point": ["circle-color"],
      },
    });

    map.addControl(geocoder);
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(legend, "bottom-left");

    map.on("load", () => {
      map.addSource("transfers", {
        type: "geojson",
        data: transfers,
      });

      map.addLayer(
        {
          id: "transfers-point",
          type: "circle",
          source: "transfers",
          paint: {
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              1,
              2,
              24,
              10,
            ],
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "ufM2U"],
              0,
              "#72d127",
              20,
              "#86c322",
              40,
              "#95b51d",
              60,
              "#a1a618",
              80,
              "#ab9813",
              100,
              "#b3880e",
              120,
              "#bb780a",
              140,
              "#c06607",
              160,
              "#c55205",
              180,
              "#ca3804",
              200,
              "#cd0404",
            ],
          },
          metadata: {
            name: "Precio de transacción (UF/m²)",
            labels: {
              0: "0",
              20: "",
              40: "",
              60: "",
              80: "",
              100: "100",
              120: "",
              140: "",
              160: "",
              180: "",
              200: "200",
            },
          },
        },
        "waterway-label"
      );

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      map.on("mouseenter", "transfers-point", (e) => {
        map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup
          .setLngLat(coordinates)
          .setHTML(
            `
            <div class="container">
              <div class="row mt-1 mb-2">
                <div class="col">
                  <h6>Propiedad:</h6>
                </div>
              </div>
              <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">UF/m²:</span>
                    <span class="text-muted">
                      ${
                        e?.features[0]?.properties?.ufM2U
                          ? e?.features[0]?.properties?.ufM2U + " UF/m²"
                          : "Sin información"
                      }
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Superficie construida:</span>
                    <span class="text-muted">
                      ${
                        e?.features[0]?.properties?.supCons
                          ? e?.features[0]?.properties?.supCons + " m²"
                          : "Sin información"
                      }
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Año:</span>
                    <span class="text-muted">
                      ${
                        e?.features[0]?.properties?.ano
                          ? e?.features[0]?.properties?.ano
                          : "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Precio Transf:</span>
                    <span class="text-muted">
                      ${
                        e?.features[0]?.properties?.uf
                          ? e?.features[0]?.properties?.uf + " UF"
                          : "Sin información"
                      }
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Comuna:</span>
                    <span class="text-muted">
                      ${
                        e?.features[0]?.properties?.comuna &&
                        typeof e?.features[0]?.properties?.tipo === "string"
                          ? e?.features[0]?.properties?.comuna
                              ?.split(" ")
                              ?.map(
                                (word) =>
                                  word[0]?.toUpperCase() +
                                  word?.toLowerCase()?.substring(1)
                              )
                              ?.join(" ")
                          : "Sin información"
                      }</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="font-weight-bold">Tipo:</span>
                    <span class="text-muted">
                      ${
                        e?.features[0]?.properties?.tipo &&
                        typeof e?.features[0]?.properties?.tipo === "string"
                          ? e?.features[0]?.properties?.tipo
                              ?.split(" ")
                              ?.map(
                                (word) =>
                                  word[0]?.toUpperCase() +
                                  word?.toLowerCase()?.substring(1)
                              )
                              ?.join(" ")
                          : "Sin información"
                      }</span>
                  </div>
                </div>
              </div>
            </div>`
          )
          .addTo(map);
      });

      map.on("mouseleave", "transfers-point", () => {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });
    });
  },
};
</script>

<style>
#map-admin {
  top: 0%;
  bottom: 0%;
  left: 0%;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;

  border-radius: 0.5rem;
  border: 1px solid #dee2e6;

  min-height: 86vh;
}

.map-admin-spinner {
  min-height: 86vh;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
