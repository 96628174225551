/* eslint-disable no-unused-vars */
import router from "@/router";
import propertyService from "@/services/property.service";
import obtainDocuments from "@/services/obtainDocuments.service";
import pageNumberYearService from "@/services/pageNumberYear.service";
import simpleRealEstateService from "@/services/simpleRealEstate.service";
import documentService from "@/services/document.service";
import simpleDocumentsService from "@/services/simpleDocuments.service";
import requestService from "@/services/request.service";
import getPropertyByRole from "@/services/propertyByRole.service";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";

export default {
  state: {
    selectedProperty: {},
    pageNumberYear: {},
    documents: {},
    document: {},
    propertiesList: [],
    propertyConfirm: {},
    requestSelectedProperty: {},
    requestsList: [],
    selectedRequest: {},
    simpleDocuments: {},
    simpleDocumentsSelected: [],
    documentsTotalSale: 0,
    resumeProperty: {},
    infoProperty: {},
    isLoading: false,
    error: null,
    bbox: [],
    imagenes: [],
    indicators: {},
  },
  actions: {
    clearPropertyData({ commit }) {
      commit('setImagenes', []);
      commit('setIndicators', {});
      // Otros commits para restablecer estados relacionados con las propiedades
    },
    async fetchPropertiesList({ commit, rootState }) {
      commit("setLoading");
      const list = await propertyService.getProperties(rootState.user.owner.id);

      commit("setPropertiesList", list);
    },

    async addPropertyToList({ commit, rootState }, payload) {
      // split payload by semicolon
      const property = payload.split(";");
      /*
      if (property[0] == 'RM') {
        property[0] = 'METROPOLITANA'
      }
      */
      // create object with properties
      const values = {
        region: property[0],
        commune: property[1],
        role: property[2],
      };

      const { data } = await simpleRealEstateService.getAddressByRol(values);

      const resumeProperty = {
        user_id: `${rootState.user.owner.id}`,
        name: property[3],
        ...data.simpleRealEstateCertificate,
      };

      commit("setResumeProperty", resumeProperty);
      //commit("setLoading");
      await propertyService.saveProperty(resumeProperty).catch((e) => {
        throw new Error(e);
      });
      //commit("setLoading");
    },

    async fetchResumeProperty({ commit, rootState }, payload) {
      commit("setResumeProperty", payload);

      const values = {
        region: payload.region,
        commune: payload.commune,
        role: payload.role,
      };

      const { data } = await simpleRealEstateService.getAddressByRol(values);

      const resumeProperty = {
        user_id: `${rootState.user.owner.id}`,
        name: localStorage.getItem("propertyName"),
        ...data.simpleRealEstateCertificate,
      };

      commit("setResumeProperty", resumeProperty);
      commit("setLoading");
      commit("isFinalStep", null, { root: true });
    },

    async fetchRolSearch({ commit, rootState }, payload) {
      const data = await propertyService.rolSearch(payload);
      commit("setResumePropertyByAddress", { payload, data });

      commit("setLoading");
    },

    async fetchRolSearchByCoords({ commit, rootState }, payload) {
      const data = await propertyService.rolSearchByCoords(payload);
      console.log(data);
      commit("setResumePropertyByAddress", { payload, data });
      //commit("isFinalStep", null, { root: true });
      //commit("setLoading");
    },

    async fetchRolSearchByAddr({ commit, rootState }, payload) {
      const data = await propertyService.rolSearchByAddr(payload);
      console.log(data);
      commit("setResumePropertyByAddress", { payload, data });
      //commit("isFinalStep", null, { root: true });
      //commit("setLoading");
    },
    

    async fetchInfoSearchByRole({ commit, rootState }, payload) {
      const data = await propertyService.infoSearch(payload);
      commit("setInfoPropertyByRole", { payload, data });
      commit("setResumeProperty", { payload, data });

      //commit("setLoading");
    },

    async fetchDocuments({ commit }, { propertyId }) {
      this.commit("setLoading", { root: true });
      const list = await obtainDocuments.obtainDocuments({
        property_id: propertyId,
      });
      commit("setDocuments", list);
      this.commit("setLoading", { root: true });
    },
    async addDocuments({ dispatch }, { file, selected, name }) {
      const formattedPayload = {
        file,
        selected,
        name,
      };
      try {
        const response = await documentService.saveDocument(formattedPayload);
        dispatch("fetchDocuments");
      } catch (e) {
        throw new Error(e);
      }
    },
    async viewDocument({ commit, state }, { documentId, source }) {
      await documentService
        .viewDocument({
          document_id: documentId,
          source: source,
        })
        .then((response) => {
          commit("setDocument", response.data.document);
        });
    },
    async deleteDocument({ commit, state }, { documentId, source }) {
      await documentService.deleteDocument({
        document_id: documentId,
        source: source,
      });
    },
    async pageNumberYear({ commit, state }) {
      this.commit("setLoading", { root: true });

      if (state.selectedProperty.page === null) {
        const formattedPayload = {
          property_id: state.selectedProperty.id,
          region: state.selectedProperty.region,
          commune: state.selectedProperty.commune,
          role: state.selectedProperty.role,
        };

        const pny = await axios(
          `http://13.56.238.177:3000/bff/v1/iproperty-bff/db/rol?comuna=${state.selectedProperty.commune}&rol=${state.selectedProperty.role}`
        );

        if (pny.data?.content?.lastTransfer === null) {
          commit("setPageNumberYear", {
            hasPNY: false,
          });
        } else {
          commit("setPageNumberYear", {
            act: "pny.data.data.act",
            address: pny.data?.content?.lastTransfer?.direccion,
            owner: pny.data?.content?.lastTransfer?.comprador,
            page: pny.data?.content?.lastTransfer?.foja,
            number: pny.data?.content?.lastTransfer?.numero,
            year: pny.data?.content?.lastTransfer?.ano,
            hasPNY: true,
          });
        }
      } else {
        commit("setPageNumberYear", {
          page: state.selectedProperty.page,
          number: state.selectedProperty.number,
          year: state.selectedProperty.year,
          hasPNY: true,
        });
      }

      router.push("/corredor/propiedades/opciones-informe/pago-informe");
    },
    async savePageNumberYear({ state }) {
      const formattedPayload = {
        property_id: state.selectedProperty.id,
        page: state.pageNumberYear.page,
        number: state.pageNumberYear.number,
        year: state.pageNumberYear.year,
      };

      const response = await pageNumberYearService.savePageNumberYear(
        formattedPayload
      );
    },
    async fetchSimpleDocuments({ commit, state }) {
      commit("setLoading");
      const list = await simpleDocumentsService.obtainSimpleDocuments({
        commune: state.requestSelectedProperty.commune,
      });
      commit("setLoading");
      commit("setSimpleDocuments", list);
    },
    addSimpleDocumentList({ commit, state, getters }, document) {
      const doc = state.simpleDocuments.find(
        (element) => element.id === document.id
      );
      if (Object.keys(state.simpleDocumentsSelected).length === 0) {
        let documentsSelected = [];
        documentsSelected.push(doc);
        state.simpleDocumentsSelected = documentsSelected;
      } else {
        state.simpleDocumentsSelected.push(doc);
      }
    },
    deleteSimpleDocumentList({ commit, state, getters }, document) {
      const updateArray = state.simpleDocumentsSelected.filter(
        (e) => e.id !== document.id
      );
      commit("setSimpleDocumentsSelected", updateArray);
    },
    async fetchRequests({ commit, rootState }) {
      commit("setLoading");
      const list = await requestService.getRequests(rootState.user.owner.id);
      // const list = [
      //   {
      //     "id": 2,
      //     "totalDocuments": 1,
      //     "readyDocuments": 0,
      //     "name": "Solicitud N° 001",
      //     "status": "curso",
      //     "total_price": "5.520",
      //     "createdAt": "28-12-2022",
      //     "property": {
      //       "id": 2,
      //       "name": "Casita FNA",
      //       "region": "METROPOLITANA",
      //       "commune": "NUNOA",
      //       "rol": "3970-126"
      //     },
      //     "simple_documents": [
      //       {
      //         "id": 3,
      //         "name": "Copia de Inscripción de Dominio con Vigencia",
      //         "folders": [
      //           "cbrs"
      //         ],
      //         "status": "pendiente",
      //         file: {
      //           id: '3',
      //           type: 'application/pdf',
      //           name: 'Certificado_1.pdf',
      //         },
      //       }
      //     ]
      //   },
      // ];
      commit("setLoading");
      commit("setRequestsList", list);
    },
    async registerRequestDocument({ state, rootState }) {
      const documentsId = state.simpleDocumentsSelected.map((doc) => {
        return doc.id;
      });
      const formattedPayload = {
        property_id: state.requestSelectedProperty.id,
        user_id: rootState.user.owner.id,
        document_id: documentsId,
        total_price: state.documentsTotalSale,
      };
      const response = await requestService.registerRequest(formattedPayload);
    },
  },
  mutations: {
    setImagenes(state, payload) {
      console.log("setImagenes");
      console.log(payload);
      state.imagenes = payload;
      console.log("state.imagenes");
      console.log(state.imagenes);
    },
    setIndicators(state, payload) {
      console.log("setIndicators");
      console.log(payload);
      state.indicators = payload;
      console.log("state.indicators");
      console.log(state.indicators);
    },
    setSelectedProperty(state, payload) {
      state.selectedProperty = payload;
    },
    setPropertiesList(state, payload) {
      state.propertiesList = payload;
      this.commit("setLoading");
    },
    setResumeProperty(state, payload) {
      state.resumeProperty = payload;
    },
    setResumePropertyByAddress(state, payload) {
      console.log(payload);
      state.resumeProperty = payload;
    },
    setInfoPropertyByRole(state, payload) {
      console.log(payload);
      state.infoProperty = payload;
    },
    confirmData(state, payload) {
      Object.assign(state.propertyConfirm, {
        propertyName: payload.nombre,
        rol: payload.rol,
      });
    },
    setLoading(state) {
      state.isLoading = !state.isLoading;
    },
    setDocuments(state, payload) {
      state.documents = payload;
    },
    setPageNumberYear(state, payload) {
      state.pageNumberYear = payload;
      this.commit("setLoading", { root: true });
    },
    setDocument(state, payload) {
      state.document = payload;
    },
    setRequestSelectedProperty(state, payload) {
      state.requestSelectedProperty = payload;
    },
    setSimpleDocuments(state, payload) {
      state.simpleDocuments = payload;
    },
    setSimpleDocumentsSelected(state, payload) {
      state.simpleDocumentsSelected = payload;
    },
    setDocumentsTotalSale(state, payload) {
      state.documentsTotalSale = payload;
    },
    setRequestsList(state, payload) {
      state.requestsList = payload;
    },
    setSelectedRequest(state, payload) {
      state.selectedRequest = payload;
    },
    setBoundingBox(state, payload) {
      state.bbox = payload;
    },
  },
  getters: {
    getImagenes(state) {
      return state.imagenes;
    },
    getIndicators(state) {
      return state.indicators;
    },
    getSelectedProperty(state) {
      return state.selectedProperty;
    },
    getPropertyConfirm(state) {
      return state.propertyConfirm;
    },
    getResumeProperty(state) {
      return state.resumeProperty;
    },
    getResumePropertyByAddress(state) {
      return state.resumeProperty;
    },
    getInfoProperty(state) {
      return state.infoProperty;
    },
    getLoading(state) {
      return state.isLoading;
    },
    getPropertiesList(state) {
      return state.propertiesList;
    },
    getDocuments(state) {
      return state.documents;
    },
    getPageNumberYear(state) {
      return state.pageNumberYear;
    },
    getDocument(state) {
      return state.document;
    },
    getRequestSelectedProperty(state) {
      return state.requestSelectedProperty;
    },
    getSimpleDocuments(state) {
      return state.simpleDocuments;
    },
    getSimpleDocumentsSelected(state) {
      return state.simpleDocumentsSelected;
    },
    getDocumentsTotalSale(state) {
      return state.documentsTotalSale;
    },
    getRequestsList(state) {
      return state.requestsList;
    },
    getSelectedRequest(state) {
      return state.selectedRequest;
    },
    getBoundingBox(state) {
      return state.bbox;
    }
  },
  computed: {
  ...mapGetters([
    "getterRegions",
    "getterCommunes",
    "getRegionId",
    "getResumeProperty",
    "getInfoProperty",
    "setStep",
  ])
  }
};
