import axios from 'axios';
import Config from "@/../config.json";

const updatePassword = {
  async newPassword(values) {
    try {
      
      const data = await axios.patch(
        Config.VUE_APP_URL_UPDATE_PASSWORD,
        values
      );
      return data;
    } catch (error) {
      throw error
    }

  },

};
export default updatePassword;