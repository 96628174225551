import axios from 'axios';
import Config from "@/../config.json";

const documents = {
  async obtainDocuments(values) {
    const data = await axios.post(
      Config.VUE_APP_URL_OBTAIN_DOCUMENTS,
      values
      ) 
      .catch((err) => { return (err.response.data.message) })
      ;
    return data.data.documents;

  },

};
export default documents;